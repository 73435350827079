import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRoutes } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import moment from 'moment';
import router from './commons/ais-classes/router/router';
import ThemeProvider from './theme/ThemeProvider';
import { AuthProvider, ProtectRoute } from './commons/contexts/AuthContext';
import { TimerProvider } from './commons/contexts/TimersContext';
import { SnackBarProvider } from './commons/contexts/SnackBarContext';
import { AISCacheService } from './commons/services/cacheService';
function App() {
    const content = useRoutes(router);
    useEffect(() => {
        const handleChunkLoadError = (event) => {
            try {
                AISCacheService.setAppError({
                    message: `error : ${event.message}`,
                    date: moment(),
                    route: window.location.href,
                    user: AISCacheService.getUser().login,
                    userId: AISCacheService.getUser().id,
                });
            }
            catch (error) {
                console.error(error);
            }
            if (event.message.includes('ChunkLoadError')) {
                console.error('ChunkLoadError', event.message);
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        };
        const handleUnhandledRejection = (event) => {
            console.error('Unhandled promise rejection:', event.reason);
            try {
                AISCacheService.setAppError({
                    message: `handleUnhandledRejection : ${event.reason}`,
                    date: moment(),
                    route: window.location.href,
                    user: AISCacheService.getUser().login,
                    userId: AISCacheService.getUser().id,
                });
            }
            catch (error) {
                console.error(error);
            }
            setTimeout(() => {
                window.location.reload();
            }, 200);
        };
        window.addEventListener('error', handleChunkLoadError);
        window.addEventListener('unhandledrejection', handleUnhandledRejection);
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('error', handleChunkLoadError);
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("meta", { httpEquiv: "Pragma", content: "no-cache" }), _jsx("meta", { httpEquiv: "Cache-Control", content: "no-cache, no-store, must-revalidate" }), _jsx("meta", { httpEquiv: "Expires", content: "0" }), _jsx("meta", { charSet: "utf-8" }), _jsx("meta", { name: "viewport", content: "width=device-width, initial-scale=1" }), _jsx("title", { children: "Cabin Portal" })] }), _jsx(ThemeProvider, { children: _jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(CssBaseline, {}), _jsx(AuthProvider, { children: _jsx(TimerProvider, { children: _jsx(SnackBarProvider, { children: _jsx(ProtectRoute, { children: content }) }) }) })] }) })] }));
}
export default App;
