var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MODE_DEV, MODE_DEV_API_URL } from 'src/config/mode-development';
import { AISUserService } from './dataServices';
import { AvailableKpis } from '../constants';
const CSTE_LS_KEYS = {
    KEY_DISPATCHER: 'AIS-Dispatcher-Response',
    KEY_TOKEN: 'token',
    KEY_WR_MAINTENANCE_CRITERIA: 'AIS-WR-MAINTENANCE-CRITERIA',
    KEY_WR_DASHBOARD_PERIODE: 'AIS-WR-DASHBOARD_PERIODE',
    KEY_WR_MODULE_HREF: 'AIS-MODULE-HREF',
    KEY_WR_SUPERUSER: 'AIS-SU',
    KEY_WR_CABINAPP_THEME: 'AIS-CABINAPP_THEME',
    KEY_WR_THEME: 'AIS-appTheme',
    KEY_WR_MODULE_CURRENT: 'AIS-MODULE-CURRENT',
};
class AISCacheDataService {
    setItem(key, value) {
        window.localStorage.setItem(key, value);
    }
    removeItem(key) {
        window.localStorage.removeItem(key);
    }
    getItem(key) {
        return window.localStorage.getItem(key);
    }
    getItemAsObject(key) {
        const value = this.getItem(key);
        return value && value.length ? JSON.parse(value) : undefined;
    }
    getCustomerIdFromUser() {
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.user && token.user.CustomerId) {
            return parseInt(token.user.CustomerId, 10);
        }
        return undefined;
    }
    setDispatcherResponse(value, corporateKey) {
        const origin = window.location.origin;
        value.envVars = { ENV: value.envVars.ENV, corporateKey, origin };
        this.setItem(CSTE_LS_KEYS.KEY_DISPATCHER, JSON.stringify(value));
    }
    getDispatcherResponse() {
        return this.getItemAsObject(CSTE_LS_KEYS.KEY_DISPATCHER);
    }
    setToken(token) {
        this.setItem(CSTE_LS_KEYS.KEY_TOKEN, JSON.stringify(token));
    }
    getToken() {
        const strToken = this.getItem(CSTE_LS_KEYS.KEY_TOKEN);
        return strToken ? JSON.parse(strToken) : undefined;
    }
    setTheme(value) {
        if (value && value.colors) {
            delete value.bg_image;
            this.setItem(CSTE_LS_KEYS.KEY_WR_THEME, 'AISCustomerTheme');
            this.setItem(CSTE_LS_KEYS.KEY_WR_CABINAPP_THEME, JSON.stringify(value));
        }
        else {
            this.setItem(CSTE_LS_KEYS.KEY_WR_THEME, 'AISDefaultTheme');
        }
    }
    getTheme() {
        return this.getItem(CSTE_LS_KEYS.KEY_WR_THEME);
    }
    getCabinAppTheme() {
        const cabinAppTheme = this.getItem(CSTE_LS_KEYS.KEY_WR_CABINAPP_THEME);
        return JSON.parse(cabinAppTheme);
    }
    removeDispatcherResponse() {
        this.removeItem(CSTE_LS_KEYS.KEY_DISPATCHER);
        this.removeItem(CSTE_LS_KEYS.KEY_TOKEN);
    }
    getApiValueFromDispatcher(forceRealUrl = false) {
        if (MODE_DEV && !forceRealUrl) {
            return MODE_DEV_API_URL;
        }
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse && dispatcherResponse.newapiurl) {
            return dispatcherResponse.newapiurl;
        }
        return undefined;
    }
    getCorporateKeyFromDispatcher() {
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse &&
            dispatcherResponse.envVars &&
            dispatcherResponse.envVars.corporateKey) {
            return dispatcherResponse.envVars.corporateKey;
        }
        return null;
    }
    getSSOCustom() {
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse && dispatcherResponse.login && dispatcherResponse.login.custom) {
            const custom = dispatcherResponse.login.custom;
            return custom[0];
        }
        return undefined;
    }
    getProfileOptions() {
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.configuration && token.configuration.selectProfileOptions) {
            return token.configuration.selectProfileOptions;
        }
        return [];
    }
    getCustomerIdFromDispatcher() {
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse && dispatcherResponse.customerId) {
            return parseInt(dispatcherResponse.customerId, 10);
        }
        return undefined;
    }
    getCustomerId() {
        let customerId = this.getCustomerIdFromDispatcher();
        if (!customerId) {
            customerId = this.getCustomerIdFromUser();
            if (customerId) {
                this.setCustomerIdToDispatcherResponse(customerId);
            }
        }
        return customerId;
    }
    setCustomerIdToDispatcherResponse(customerId) {
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse) {
            dispatcherResponse.customerId = customerId;
            this.setDispatcherResponse(dispatcherResponse, dispatcherResponse.envVars.corporateKey);
        }
    }
    getEnvVarsValue(key) {
        const dispatcherResponse = this.getDispatcherResponse();
        if (dispatcherResponse && dispatcherResponse.envVars) {
            return dispatcherResponse.envVars[key]
                ? dispatcherResponse.envVars[key]
                : `Env variable ${key} unknown`;
        }
        return '';
    }
    getEnvironment() {
        const env = this.getEnvVarsValue('ENV');
        return env.charAt(0).toUpperCase() + env.slice(1).toLocaleLowerCase();
    }
    setPreviousEnvironment() {
        this.setItem('PREVIOUS-ENV', this.getEnvironment());
    }
    getPreviousEnvironment() {
        return this.getItem('PREVIOUS-ENV');
    }
    getBddNameValueFromConfiguration() {
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.configuration && token.configuration.db) {
            return token.configuration.db;
        }
        return undefined;
    }
    getGitCommitValueFromConfiguration() {
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.configuration && token.configuration.gitcommit) {
            return token.configuration.gitcommit;
        }
        return '';
    }
    getGitBranchValueFromConfiguration() {
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.configuration && token.configuration.gitbranch) {
            return token.configuration.gitbranch;
        }
        return '';
    }
    setBaseModuleHref(href) {
        this.setItem(CSTE_LS_KEYS.KEY_WR_MODULE_HREF, href);
    }
    setAppError(error) {
        this.setItem('AIS-LAST-APP-ERROR', JSON.stringify(error));
    }
    getBaseModuleHref() {
        return this.getItem(CSTE_LS_KEYS.KEY_WR_MODULE_HREF);
    }
    setCurrentModule(module) {
        this.setItem(CSTE_LS_KEYS.KEY_WR_MODULE_CURRENT, module);
    }
    getCurrentModule() {
        return this.getItem(CSTE_LS_KEYS.KEY_WR_MODULE_CURRENT);
    }
    setModeSuperUser() {
        this.setItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER, '1');
    }
    getModeSuperUser() {
        const val = this.getItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER);
        if (!val) {
            return false;
        }
        return val === '1';
    }
    removeModeSuperUser() {
        this.removeItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER);
    }
    setRequestsViewerParams(params) {
        const uuid = `dashboard-list-request`;
        sessionStorage.setItem(uuid, JSON.stringify(params));
        return uuid;
    }
    getRequestsViewerParams(key) {
        const value = sessionStorage.getItem(key);
        return value && value.length ? JSON.parse(value) : undefined;
    }
    changeUserProfile(newIdProfile, newFeatures) {
        const token = this.getToken();
        token.user.ProfileId = newIdProfile;
        token.user.permissions.features = newFeatures;
        this.setToken(token);
    }
    getUser() {
        const token = this.getToken();
        if (!token || !token.user) {
            return undefined;
        }
        return token.user;
    }
    getUserProfile() {
        const user = this.getUser();
        if (user) {
            return user.ProfileId;
        }
        return null;
    }
    isUserAirInt() {
        const user = this.getUser();
        if (user && user.email && user.email.includes('airintservices.com')) {
            return true;
        }
        return false;
    }
    getUserSettings() {
        const token = this.getToken();
        if (!token || !token.user || !token.user.settings || !token.user.settings.length) {
            return undefined;
        }
        return JSON.parse(token.user.settings);
    }
    getUserDasboardLayout() {
        const userSettings = this.getUserSettings();
        if (!userSettings || !userSettings.dasboardLayout) {
            return AvailableKpis;
        }
        return userSettings.dasboardLayout.filter(dashboard => !['KPI09', 'KPI10'].includes(dashboard.code));
    }
    setUserDasboardLayout(userLayout) {
        let userSettings = this.getUserSettings();
        if (!userSettings) {
            userSettings = {};
        }
        userSettings['dasboardLayout'] = userLayout;
        const token = this.getToken();
        token.user.settings = JSON.stringify(userSettings);
        this.setToken(token);
    }
    getUserPNFavorites() {
        const userSettings = this.getUserSettings();
        if (!userSettings || !userSettings.pn_favorites) {
            return [{ id: 'AIS|TOP5', name: 'Top 5 requested parts', detail: [] }];
        }
        if (!userSettings.pn_favorites.some(favorite => favorite.id === 'AIS|TOP5')) {
            userSettings.pn_favorites.unshift({
                id: 'AIS|TOP5',
                name: 'Top 5 requested parts',
                detail: [],
            });
        }
        return userSettings.pn_favorites;
    }
    setUserPNFavorites(favorites) {
        let userSettings = this.getUserSettings();
        if (!userSettings) {
            userSettings = {};
        }
        userSettings['pn_favorites'] = favorites;
        const token = this.getToken();
        token.user.settings = JSON.stringify(userSettings);
        this.setToken(token);
    }
    getMaintenanceFavorites() {
        const userSettings = this.getUserSettings();
        return (userSettings === null || userSettings === void 0 ? void 0 : userSettings.maintenance_favorites) || [];
    }
    setUserMaintenanceFavorites(favorites) {
        let userSettings = this.getUserSettings();
        if (!userSettings) {
            userSettings = {};
        }
        userSettings['maintenance_favorites'] = favorites;
        const token = this.getToken();
        token.user.settings = JSON.stringify(userSettings);
        this.setToken(token);
    }
    getPeriodeDashboard() {
        const userSettings = this.getUserSettings();
        if (!userSettings || !userSettings.dashboardPeriode) {
            return 'm-3';
        }
        return userSettings.dashboardPeriode;
    }
    setPeriodeDashboard(periode) {
        return __awaiter(this, void 0, void 0, function* () {
            let userSettings = this.getUserSettings();
            if (!userSettings) {
                userSettings = {};
            }
            userSettings['dashboardPeriode'] = periode;
            const token = this.getToken();
            token.user.settings = JSON.stringify(userSettings);
            this.setToken(token);
            const { id } = AISCacheService.getUser();
            yield AISUserService.updateDashboardPeriode(id, periode);
        });
    }
    getCriteriaMaintenance(withRfid = false) {
        const userSettings = this.getUserSettings();
        if (!userSettings || !userSettings.maintenanceCriteria) {
            return {
                periode: 'm-1',
                groupBy: 'month',
                excludeRfid: true,
                source: 'MAINTENANCE',
                includeDeleted: false,
            };
        }
        const criteria = userSettings.maintenanceCriteria;
        if (criteria) {
            if (!criteria.excludeRfid) {
                criteria.excludeRfid = true;
            }
            if (!criteria.source) {
                criteria.source = 'MAINTENANCE';
            }
            if (!withRfid) {
                criteria.source = 'MAINTENANCE';
            }
            if (!criteria.includeDeleted) {
                criteria.includeDeleted = false;
            }
            // For performance reasons ( iberia) we are obliged to limit the period
            // if (['m-3', 'm-6', 'm-12', 'm-24', 'all'].includes(criteria.periode)) {
            //   criteria.periode = 'm-1';
            //   criteria.groupBy = 'month';
            // }
        }
        return (criteria || {
            periode: 'm-1',
            groupBy: 'month',
            excludeRfid: true,
            source: 'MAINTENANCE',
            includeDeleted: false,
        });
    }
    setCriteriaMaintenance(criteria) {
        return __awaiter(this, void 0, void 0, function* () {
            let userSettings = this.getUserSettings();
            if (!userSettings) {
                userSettings = {};
            }
            userSettings['maintenanceCriteria'] = criteria;
            const token = this.getToken();
            token.user.settings = JSON.stringify(userSettings);
            this.setToken(token);
            const { id } = AISCacheService.getUser();
            yield AISUserService.updateMaintenanceCriteria(id, criteria);
        });
    }
    getBuildTaskCheckFileKey() {
        let key = 'taskCheckFile_';
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (token && token.configuration && token.configuration.customer) {
            key += token.configuration.customer.oaci;
            key += `_${token.configuration.customer.id}`;
            key += `_${this.getEnvironment()}`;
        }
        return key;
    }
    setTaskCache(data) {
        const oaci = AISCacheService.getOACI();
        const env = AISCacheService.getEnvironment();
        this.setItem(`TASK-BUILD-CACHE-${oaci}-${env}`, JSON.stringify(data));
    }
    getTaskCacheUDID() {
        const oaci = AISCacheService.getOACI();
        const env = AISCacheService.getEnvironment();
        const task = this.getItemAsObject(`TASK-BUILD-CACHE-${oaci}-${env}`);
        return task ? task.taskUdid : null;
    }
    setTaskCheckFile(data) {
        this.setItem('CHECK_FILE_OK', JSON.stringify(data));
    }
    getTaskCheckFile() {
        return this.getItemAsObject('CHECK_FILE_OK');
    }
    taskFileShowRecap(key) {
        if (key === 'none') {
            return false;
        }
        const task = this.getTaskCheckFile();
        return !!(task && task.key === key);
    }
    removeTaskCheckFile() {
        this.removeItem('CHECK_FILE_OK');
    }
    getOACI() {
        var _a;
        const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);
        if (!token) {
            return null;
        }
        return (_a = token === null || token === void 0 ? void 0 : token.configuration) === null || _a === void 0 ? void 0 : _a.customer.oaci;
    }
    getJWTToken() {
        const fullToken = this.getToken();
        return (fullToken === null || fullToken === void 0 ? void 0 : fullToken.token) || null;
    }
    getJWTTokenDecryted() {
        const portalJWTToken = this.getJWTToken();
        const base64Url = portalJWTToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(c => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
            .join(''));
        return jsonPayload;
    }
    decodeToken(jwtToken) {
        const base64Url = jwtToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(c => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    tokenIsExpired() {
        const val = this.getItem('tokenIsExpired');
        return val && val.length ? val === 'true' : false;
    }
    setTokenIsExpired() {
        this.setItem('tokenIsExpired', 'true');
    }
    removeTokenIsExpired() {
        this.removeItem('tokenIsExpired');
    }
}
export const AISCacheService = new AISCacheDataService();
// function genUuid() {
//   return [1, 2, 3, 4].map(() => (~~(Math.random() * 1e9)).toString(36)).join('-');
// }
