var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AISCacheService } from './cacheService';
import api, { apiDispatcher, ENDPOINTS } from './api';
export class AISDataService {
    constructor() {
        this.additionalsParams = [
            { name: 'udid', value: 'webreporting' },
            { name: 'deviceName', value: 'webreporting' },
        ];
    }
    // private extraParam : string = "&udid=webreporting&deviceName=webreporting" ;
    setBearer() {
        const token = this.getBearerToken();
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        return false;
    }
    getBearerToken() {
        return AISCacheService.getJWTToken();
    }
    getAdditionalsParams(data) {
        if (!data) {
            data = '?';
        }
        this.additionalsParams.forEach(p => {
            data += `${data === '?' ? '' : '&'} ${p.name}=${p.value}`;
        });
        return data;
    }
    getAdditionalsParamsObject(data) {
        if (!data) {
            data = {};
        }
        this.additionalsParams.forEach(p => {
            data[p.name] = p.value;
        });
        return data;
    }
    treatmentResponse(response, url) {
        return __awaiter(this, void 0, void 0, function* () {
            if ([401, 403].includes(response.data.status)) {
                console.error(`Session API Expired -> URL checked : ${url} `);
                AISCacheService.setTokenIsExpired();
                yield new Promise(resolve => {
                    setTimeout(resolve, 1000);
                });
                return false;
            }
            return true;
        });
    }
    get(url) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setBearer();
            const response = yield api.get(url);
            yield this.treatmentResponse(response, url);
            return response;
        });
    }
    post(url, data, noBearer, config) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setBearer();
            const response = yield api.post(url, data, config || {
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0',
                    crossDomain: true,
                    Accept: '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Accept',
                },
            });
            yield this.treatmentResponse(response, url);
            return response;
        });
    }
    put(url, data, noBearer) {
        this.setBearer();
        return api.put(url, data);
    }
    delete(url, noBearer) {
        this.setBearer();
        return api.delete(url);
    }
    buildErrorDispatcher(errors) {
        let error = '';
        errors.forEach(err => {
            for (const property in err) {
                if (error.length > 0) {
                    error += ', ';
                }
                error += err[property];
            }
        });
        return [{ error }];
    }
    buildErrorFromResponse(errors) {
        const error = this.buildErrorDispatcher(errors);
        return error[0].error;
    }
    buildErrorAxios(error) {
        return [{ error: error.message, fullError: error }];
    }
    buildMessageErrorAxios(error) {
        return error.message;
    }
    getDbTable(tableName) {
        return this.get(`${ENDPOINTS.DB.GETALLDATAFROMTABLE}/${tableName}`);
    }
    getTablesList() {
        return this.get(`${ENDPOINTS.DB.GETTABLESLIST}`);
    }
    syncModel(modelName) {
        return this.post(`${ENDPOINTS.DB.SYNCMODEL}`, { modelName });
    }
    restartAPI() {
        try {
            this.get(`${ENDPOINTS.RESTARTAPI}`);
        }
        catch (error) {
            /* empty */
        }
    }
    postDbTable(tableName, data) {
        if (data.id) {
            return this.put(ENDPOINTS.DB.PUT, { tableName, data });
        }
        return this.post(ENDPOINTS.DB.POST, { tableName, data });
    }
    bulkDeleteDbTable(tableName, data) {
        return this.post(ENDPOINTS.DB.DELETEBULK, { tableName, data });
    }
}
class AISDispatcherDataService extends AISDataService {
    // AFR-1810
    loginToDispatcher(corporateKey) {
        return apiDispatcher.post(ENDPOINTS.DISPATCHER.CHECK, {
            customerKey: corporateKey,
        });
    }
    getEnvs() {
        return apiDispatcher.get(ENDPOINTS.DISPATCHER.GET_ENVS);
    }
    getPinValues() {
        return apiDispatcher.get(ENDPOINTS.DISPATCHER.GET_PINVALUES);
    }
    buildEnvsFormated(envsList, pinValuesList, customersList) {
        envsList.forEach(env => {
            const pinValues = pinValuesList.filter(row => row.envid === env.id);
            pinValues.forEach(pin => {
                const customer = customersList.find(customer => customer.id === parseInt(pin.envcustomer, 10));
                pin['customer'] = customer || { name: ` ${pin.envoaci} / Customer ` };
            });
            env['corporateKeyDetail'] = pinValues;
        });
        return envsList.sort((env1, env2) => (env1.envname > env2.envname ? 1 : -1));
    }
    getUserEnv(apiUrl, customerId) {
        return __awaiter(this, void 0, void 0, function* () {
            const envUser = {
                ENV: 'UNDEFINED',
                corporateKey: 'UNDEFINED',
                origin: 'UNDEFINED',
            };
            const apiToFind = apiUrl === 'http://localhost:6060/' ? 'https://api1787.airintservices.com/' : apiUrl;
            const { data: pinValues } = yield this.getPinValues();
            const { data: envList } = yield this.getEnvs();
            const pinValue = pinValues.results.find(row => row.newapiurl === apiToFind && parseInt(row.envcustomer, 10) === customerId);
            if (pinValue) {
                const envId = pinValue.envid;
                const env = envList.results.find(currentEnv => currentEnv.id === envId);
                if (env) {
                    envUser.corporateKey = `${pinValue.envoaci}-${pinValue.pinvalue}`;
                    envUser.ENV = env.env.split('_')[1];
                    envUser.origin = window.location.origin;
                }
            }
            return envUser;
        });
    }
}
class AISUserDataService extends AISDataService {
    login2(login, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.post(ENDPOINTS.LOGIN, this.getAdditionalsParamsObject({ login, password }), true);
            return response.data;
        });
    }
    login(login, password, env, uuid) {
        if (uuid) {
            return this.post(ENDPOINTS.LOGIN_UUID, this.getAdditionalsParamsObject({ uuid, env }), true);
        }
        return this.post(ENDPOINTS.LOGIN, this.getAdditionalsParamsObject({ login, password, env }), true);
    }
    logout() {
        return this.post(ENDPOINTS.LOGOUT, this.getAdditionalsParamsObject({ token: this.getBearerToken() }));
    }
    check() {
        return this.get(ENDPOINTS.CHECK + this.getAdditionalsParams());
    }
    checkApiConnection() {
        return this.get(ENDPOINTS.CHECKAPICONNEXION);
    }
    getOneUser(idUser) {
        return this.post(ENDPOINTS.USERS.GETONE, this.getAdditionalsParamsObject({ id: idUser }));
    }
    getAllUsers() {
        return this.get(ENDPOINTS.USERS.GETALL + this.getAdditionalsParams());
    }
    createUser(user) {
        return this.post(ENDPOINTS.USERS.CREATEUSER, this.getAdditionalsParamsObject(user));
    }
    updateUser(user) {
        //  router.post('/api/webreporting/user/edit', editUserInformations);
        return this.post(ENDPOINTS.USERS.UPDATEUSER, this.getAdditionalsParamsObject(user));
    }
    deleteUser(idUser) {
        return this.delete(ENDPOINTS.USERS.DELETEUSER + this.getAdditionalsParams(`/${idUser}`));
    }
    deleteUsers(idUsers) {
        return this.post(ENDPOINTS.USERS.DELETEUSERS, this.getAdditionalsParamsObject({ idUsers }));
    }
    disableUsers(idUsers) {
        return this.post(ENDPOINTS.USERS.DISABLEDUSERS, this.getAdditionalsParamsObject({ idUsers }));
    }
    updateListColumns(columnsSelected) {
        return this.post(ENDPOINTS.USERS.UPDATELISTCOLUMNS, this.getAdditionalsParamsObject({ columnsSelected }));
    }
    updateDashboardLayout(idUser, layout) {
        return this.post(ENDPOINTS.USERS.UPDATEDASHBORDLAYOUT, this.getAdditionalsParamsObject({ idUser, layout }));
    }
    updateDashboardPeriode(idUser, periode) {
        return this.post(ENDPOINTS.USERS.UPDATEDASHBORDPERIODE, this.getAdditionalsParamsObject({ idUser, periode }));
    }
    updateMaintenanceCriteria(idUser, criteria) {
        return this.post(ENDPOINTS.USERS.UPDATEMAINTENANCECRITERIA, this.getAdditionalsParamsObject({ idUser, criteria }));
    }
    getAuthUrlSSO(customer) {
        return `${api.getUri()}${ENDPOINTS.GETAUTHSSOURL}/${customer}`;
    }
    getAuthUrlSSOHabile() {
        return this.get(ENDPOINTS.GETAUTHSSOURL_HABILE);
    }
    fakeRenderAuthenticated() {
        return `${api.getUri()}${ENDPOINTS.FAKE_RENDER_AUTHENTICATED}`;
    }
    setFrontUrlInSession(origin) {
        return this.post(ENDPOINTS.SET_ORIGIN_IN_SESSION, this.getAdditionalsParamsObject({ origin }));
    }
    updateUserSessionProfile(newProfile) {
        return this.post(ENDPOINTS.USERS.UPDATEUSERSESSIONPROFILE, this.getAdditionalsParamsObject({ newProfile }));
    }
    updatePNFavorites(idUser, favorites) {
        return this.post(ENDPOINTS.USERS.UPDATE_PN_FAVORITES, this.getAdditionalsParamsObject({ idUser, favorites }));
    }
    updateMaintenanceFavorites(idUser, favorites) {
        return this.post(ENDPOINTS.USERS.UPDATE_MAINTENANCE_FAVORITES, this.getAdditionalsParamsObject({ idUser, favorites }));
    }
}
class AISReportDataService extends AISDataService {
    getAllReports() {
        return this.get(ENDPOINTS.WEBREPORTING.REPORTS.GETRFIDINVENTORIES + this.getAdditionalsParams());
    }
}
class AISPermissionsDataService extends AISDataService {
    getConfig() {
        return this.get(ENDPOINTS.PERMISSIONS.GETPERMISSIONSCONFIG + this.getAdditionalsParams());
    }
    createProfile(profile) {
        return this.post(ENDPOINTS.PERMISSIONS.CREATEPROFILE, this.getAdditionalsParamsObject(profile));
    }
    updateProfile(profile) {
        return this.post(ENDPOINTS.PERMISSIONS.UPDATEPROFILE, this.getAdditionalsParamsObject(profile));
    }
    getOneProfile(idProfile) {
        return this.post(ENDPOINTS.PERMISSIONS.GETONE, this.getAdditionalsParamsObject({ id: idProfile }));
    }
    deleteProfile(idProfile) {
        return this.delete(ENDPOINTS.PERMISSIONS.DELETEPROFILE + this.getAdditionalsParams(`/${idProfile}`));
    }
    deleteProfileFeature(idProfile, idFeature) {
        return this.post(ENDPOINTS.PERMISSIONS.DELETEPROFILEFEATURE, this.getAdditionalsParamsObject({
            idProfile,
            idFeature,
        }));
    }
}
class AISDevicesDataService extends AISDataService {
    getAllDevices() {
        return this.get(ENDPOINTS.DEVICES.GETALL + this.getAdditionalsParams());
    }
    deleteDevice(idDevice) {
        return this.post(ENDPOINTS.DEVICES.DELETE, this.getAdditionalsParamsObject({ id: idDevice }));
    }
    activeDevice(idDevice) {
        return this.post(ENDPOINTS.DEVICES.ACTIVE, this.getAdditionalsParamsObject({ id: idDevice }));
    }
    changeStatus(idDevice, newStatus) {
        return this.post(ENDPOINTS.DEVICES.CHANGESTATUS, this.getAdditionalsParamsObject({ id: idDevice, status: newStatus }));
    }
}
class AISDocumentsDataSubmissionService extends AISDataService {
    getAllEquipmentTypeAndCMM() {
        return this.get(ENDPOINTS.DOCUMENTS.POSTUPLOADCMM + this.getAdditionalsParams());
    }
    getListUpload() {
        return this.get(ENDPOINTS.DOCUMENTS.LISTUPLOADCMM + this.getAdditionalsParams());
    }
    getAllDocuments() {
        return this.get(ENDPOINTS.DOCUMENTS.GETALL + this.getAdditionalsParams());
    }
    updateStatus(historyTechnicalDoc) {
        return this.put(ENDPOINTS.DOCUMENTS.UPDATESTATUS, this.getAdditionalsParamsObject(historyTechnicalDoc));
    }
    updateStatusDate(date, id) {
        return this.put(ENDPOINTS.DOCUMENTS.UPDATESTATUSDATE, this.getAdditionalsParamsObject({ date, id }));
    }
    updateTechnicalDoc(technicalDocParams) {
        return this.post(ENDPOINTS.DOCUMENTS.UPDATETECHNICALDOC, this.getAdditionalsParamsObject(technicalDocParams));
    }
    createTechnicalDoc(data, onProgress, onTerminate, onFailed) {
        api
            .postForm(ENDPOINTS.DOCUMENTS.POSTUPLOADCMM, data, {
            onUploadProgress: ProgressEvent => {
                onProgress(Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100));
            },
        })
            .then(res => {
            onTerminate(res);
        })
            .catch(error => {
            onFailed(error);
        });
    }
    getDetails(idDocument) {
        return this.post(ENDPOINTS.DOCUMENTS.GETDETAILS, this.getAdditionalsParamsObject({ id: idDocument }));
    }
    getStatus(idDocument) {
        return this.post(ENDPOINTS.DOCUMENTS.GETSTATUS, this.getAdditionalsParamsObject({ id: idDocument }));
    }
}
class AISHistoryDataService extends AISDataService {
    getHistory(limit) {
        return this.post(ENDPOINTS.HISTORY.GETHISTORY, this.getAdditionalsParamsObject(limit ? { limit } : null));
    }
}
class AISNotificationDataService extends AISDataService {
    constructor() {
        super(...arguments);
        this.storeNotification = null;
    }
    storeDeleteNotif(revision, nonce) {
        if (!this.storeNotification) {
            return;
        }
        this.storeNotification.then(res => {
            res.data.results = res.data.results.filter(notif => notif.nonce !== nonce && notif.revision !== revision);
        });
    }
    storeAddNotif(newNotif) {
        if (!this.storeNotification) {
            return;
        }
        const data = {
            nonce: newNotif.nonce,
            title: newNotif.data.title,
            date: newNotif.data.date_send,
            subtitle: newNotif.data.subtitle,
            body: newNotif.data.body,
            revision: newNotif.newIdRevisionIndex,
            countUser: newNotif.countUser,
            countRead: newNotif.countRead,
        };
        this.storeNotification.then(res => {
            if (res && res.data && res.data.results) {
                res.data.results.unshift(data);
            }
        });
    }
    getListNotification() {
        if (this.storeNotification) {
            return this.storeNotification;
        }
        this.storeNotification = this.get(ENDPOINTS.NOTIFICATIONS.LIST + this.getAdditionalsParams());
        return this.storeNotification;
    }
    createNotification(data) {
        const notif = this.post(ENDPOINTS.NOTIFICATIONS.CREATE, this.getAdditionalsParamsObject(data));
        notif.then(newNotif => {
            if (newNotif.data && newNotif.data.results && newNotif.data.results.length > 0) {
                const dataNotif = newNotif.data.results[0];
                dataNotif['countUser'] = dataNotif['countUsers'];
                dataNotif['countRead'] = 0;
                this.storeAddNotif(dataNotif);
            }
        });
        return notif;
    }
    deleteNotification(revision, nonce) {
        this.storeDeleteNotif(revision, nonce);
        return this.post(ENDPOINTS.NOTIFICATIONS.DELETE, this.getAdditionalsParamsObject({ revision, nonce }));
    }
    getLastUserNotification() {
        return this.get(ENDPOINTS.NOTIFICATIONS.LIST_USER + this.getAdditionalsParams());
    }
    setNotificationReadForUser(idNotification) {
        return this.post(ENDPOINTS.NOTIFICATIONS.SET_ISREADED, this.getAdditionalsParamsObject({ idNotification }));
    }
}
class AISKpiDataService extends AISDataService {
    getCounters() {
        return this.get(ENDPOINTS.KPIS.COUNTERS + this.getAdditionalsParams());
    }
}
class AISTablesDataService extends AISDataService {
    getListAircraft() {
        return this.get(ENDPOINTS.TABLES.AIRCRAFT.LIST + this.getAdditionalsParams());
    }
    updateAircraft(aircraft) {
        return this.post(ENDPOINTS.TABLES.AIRCRAFT.UPDATE, this.getAdditionalsParamsObject({ aircraft }));
    }
    updateKpi(kpi) {
        return this.post(ENDPOINTS.TABLES.KPIS.UPDATE, this.getAdditionalsParamsObject({ kpi }));
    }
    getListKpi() {
        return this.get(ENDPOINTS.TABLES.KPIS.LIST + this.getAdditionalsParams());
    }
    getDataMappingAircraftTypeNarrowWideBodies() {
        return this.get(ENDPOINTS.TABLES.DATAMAPPING.LIST_NARROW_WIDE_BODIES + this.getAdditionalsParams());
    }
    updateRowDataMapping(row) {
        return this.post(ENDPOINTS.TABLES.DATAMAPPING.UPDATEROW, this.getAdditionalsParamsObject({ row }));
    }
}
class AISSettingsDataService extends AISDataService {
    getCustomerOptions() {
        return this.get(ENDPOINTS.GENERALSETTINGS.GETCUSTOMEROPTIONS + this.getAdditionalsParams());
    }
    updateCustomerOptions(settings) {
        return this.post(ENDPOINTS.GENERALSETTINGS.UPDATE, this.getAdditionalsParamsObject({ settings }));
    }
    getTheme(customerId) {
        return this.post(ENDPOINTS.GENERALSETTINGS.GETTHEME, this.getAdditionalsParamsObject({ customerId }));
    }
    setTheme(theme) {
        return this.post(ENDPOINTS.GENERALSETTINGS.SETTHEME, this.getAdditionalsParamsObject(theme));
    }
    getSSOConfig(customerId) {
        const apiURL = api.getUri();
        const env = AISCacheService.getEnvironment();
        return this.post(ENDPOINTS.GENERALSETTINGS.GET_SSO_CONFIG, this.getAdditionalsParamsObject({ customerId, env, apiURL }));
    }
    setSSOConfig(config) {
        return this.post(ENDPOINTS.GENERALSETTINGS.SET_SSO_CONFIG, this.getAdditionalsParamsObject(config));
    }
}
class AISCustomerInteropDataService extends AISDataService {
    getCustomerInterop() {
        return this.get(ENDPOINTS.CUSTOMERINTEROP.LIST + this.getAdditionalsParams());
    }
    updateCustomerOptions(settings) {
        return this.post(ENDPOINTS.GENERALSETTINGS.UPDATE, this.getAdditionalsParamsObject({ settings }));
    }
    getOneById(customerInteropId) {
        return this.post(ENDPOINTS.CUSTOMERINTEROP.GETONEBYID, this.getAdditionalsParamsObject({ id: customerInteropId }));
    }
    updateCustomerInterop(data) {
        return this.post(ENDPOINTS.CUSTOMERINTEROP.UPDATE, this.getAdditionalsParamsObject(data));
    }
    deleteCustomerInterop(id) {
        return this.delete(ENDPOINTS.CUSTOMERINTEROP.DELETE + this.getAdditionalsParams(`/${id}`));
    }
    getMaxIdList() {
        return this.get(ENDPOINTS.CUSTOMERINTEROP.GETMAXIDLIST + this.getAdditionalsParams());
    }
}
class AISEnvsVarDataService extends AISDataService {
}
class AISWebReportingDataService extends AISDataService {
    getAvailableFilters() {
        return this.get(ENDPOINTS.WEBREPORTING.GETAVAILABLEFILTERS + this.getAdditionalsParams());
    }
    getMaintenanceData(criteria) {
        return this.post(ENDPOINTS.WEBREPORTING.MAINTENANCE.GETDATA, this.getAdditionalsParamsObject(criteria));
    }
    getCounters(periode) {
        return this.post(ENDPOINTS.WEBREPORTING.DASHBOARD.COUNTERS, this.getAdditionalsParamsObject({ periode }));
    }
    calculKpis(periode) {
        return this.post(ENDPOINTS.WEBREPORTING.DASHBOARD.CALCULATE, this.getAdditionalsParamsObject({ periode }));
    }
    calculKpisCRON() {
        return this.get(ENDPOINTS.WEBREPORTING.DASHBOARD.CALCULATECRON + this.getAdditionalsParams());
    }
    getKpisResults(periode) {
        return this.post(ENDPOINTS.WEBREPORTING.DASHBOARD.GETRESULTS, this.getAdditionalsParamsObject({ periode }));
    }
    getKpiStandAloneResult(periode, code) {
        return this.post(ENDPOINTS.WEBREPORTING.DASHBOARD.GET_ONE_KPI_RESULT, this.getAdditionalsParamsObject({ periode, code }));
    }
    getWeeklyReportResults(periode) {
        return this.post(ENDPOINTS.WEBREPORTING.REPORTS.GETWEEKLYREPORT, this.getAdditionalsParamsObject({ periode }));
    }
    updateKpi(kpi) {
        return this.post(ENDPOINTS.TABLES.KPIS.UPDATE, this.getAdditionalsParamsObject({ kpi }));
    }
    getAircraftList() {
        return this.get(ENDPOINTS.WEBREPORTING.FLEET.AIRCRAFTLIST + this.getAdditionalsParams());
    }
    searchPartNumber(searchTerm) {
        return this.post(ENDPOINTS.WEBREPORTING.SEARCHPN.SEARCHTERM, this.getAdditionalsParamsObject({ searchTerm }));
    }
    searchRequestsByPartNumber(listPn, year) {
        return this.post(ENDPOINTS.WEBREPORTING.SEARCHPN.GETRESULT, this.getAdditionalsParamsObject({ listPn, year }));
    }
    searchPNbyAISFavoris(code) {
        return this.post(ENDPOINTS.WEBREPORTING.SEARCHPN.GETRESULTBYCODE, this.getAdditionalsParamsObject({ code }));
    }
    getMaintenanceChart(criteria, groupBy, filters) {
        return this.post(ENDPOINTS.WEBREPORTING.MAINTENANCEV2.GETCHART, this.getAdditionalsParamsObject({ criteria, groupBy, filters }));
    }
    getMaintenanceRequestsPaginated(criteria, params) {
        return this.post(ENDPOINTS.WEBREPORTING.MAINTENANCEV2.GETREQUESTS_PAGINATED, this.getAdditionalsParamsObject({ criteria, params }));
    }
    getMaintenanceRequests(criteria_1, filters_1, rowsUnSelected_1) {
        return __awaiter(this, arguments, void 0, function* (criteria, filters, rowsUnSelected, flatten = false, progress = null) {
            const params = {
                rowsUnSelected,
                filters,
                offset: 0,
                limit: 1000,
                flatten,
            };
            let hasMoreData = true;
            let allRequests = [];
            while (hasMoreData) {
                const { data: response } = yield this.post(ENDPOINTS.WEBREPORTING.MAINTENANCEV2.GETREQUESTS, this.getAdditionalsParamsObject({ criteria, params }));
                const { hasMore, requests } = response.results[0];
                allRequests = [...allRequests, ...requests];
                hasMoreData = hasMore;
                params.offset += params.limit;
                if (progress) {
                    progress(allRequests.length);
                }
            }
            return allRequests;
        });
    }
}
class AISRequestDataService extends AISDataService {
    getRequest(id) {
        return this.post(ENDPOINTS.REQUEST.READ, this.getAdditionalsParamsObject({ id }));
    }
    buildRequestPDF(ids) {
        return this.post(ENDPOINTS.REQUEST.BUILDPDF, this.getAdditionalsParamsObject({ ids }), false);
    }
    getRequestPdf(filePath) {
        return this.post(ENDPOINTS.REQUEST.GETPDF, this.getAdditionalsParamsObject({ filePath }), false, {
            responseType: 'blob',
        });
    }
    checkAnomalieRequest(id) {
        return this.post(ENDPOINTS.REQUEST.CHECKANOMALIE, this.getAdditionalsParamsObject({ id }), false);
    }
    modifyRequests(changes) {
        return this.post(ENDPOINTS.REQUEST.MODIFYREQUESTS, this.getAdditionalsParamsObject({ changes }));
    }
    getFlattenRequestsByIds(idsRequest) {
        return this.post(ENDPOINTS.REQUEST.GETREQUESTFLATTEN, this.getAdditionalsParamsObject({ idsRequest }));
    }
    getFlattenRequestsByWhere(limit, offset, where, orderBy) {
        return this.post(ENDPOINTS.WEBREPORTING.REQUEST.GETREQUESTFLATTEN_BYWHERE, this.getAdditionalsParamsObject({ limit, offset, where, orderBy }));
    }
    getAllRequestsByWhere(where_1, orderBy_1) {
        return __awaiter(this, arguments, void 0, function* (where, orderBy, progress = null) {
            let hasMoreData = true;
            let allRequests = [];
            let offset = 0;
            const limit = 1000;
            while (hasMoreData) {
                const { data: response } = yield this.post(ENDPOINTS.WEBREPORTING.REQUEST.GETREQUESTFLATTEN_BYWHERE, this.getAdditionalsParamsObject({ limit, offset, where, orderBy }));
                const { hasMore, requests } = response.results[0];
                allRequests = [...allRequests, ...requests];
                hasMoreData = hasMore;
                offset += limit;
                if (progress) {
                    progress(allRequests.length);
                }
            }
            return allRequests;
        });
    }
}
class AISBucketDataService extends AISDataService {
    getFile(param) {
        return this.post(ENDPOINTS.BUCKET.LOADFILEPATH, this.getAdditionalsParamsObject(param));
    }
}
class AISAdminToolsDataService extends AISDataService {
    constructor() {
        super(...arguments);
        this.getLastCacheVersion = () => {
            const oaci = AISCacheService.getOACI();
            return this.get(`${ENDPOINTS.TASK_JOB.CACHE.GETLASTVERSION}${oaci} `);
        };
        this.startJobBuildCache = () => {
            const oaci = AISCacheService.getOACI();
            if (!oaci) {
                return null;
            }
            return this.post(ENDPOINTS.TASK_JOB.CACHE.BUILD, this.getAdditionalsParamsObject({ CustomerOaci: oaci, job: 'cache_for_customer' }));
        };
        this.getJobStatus = (udid) => {
            // const oaci = AISCacheService.getOACI();
            // // if (!oaci) {
            // //   return null;
            // // }
            return this.get(`${ENDPOINTS.TASK_JOB.GETSTATUS}${udid} `);
        };
        this.buildArchive = () => {
            const customerId = AISCacheService.getCustomerId();
            if (!customerId) {
                return null;
            }
            return this.get(`${ENDPOINTS.FILESANDCACHE.ARCHIVE.BUILD}${customerId}`);
        };
        this.buildArchiveWorker = () => {
            const customerId = AISCacheService.getCustomerId();
            if (!customerId) {
                return null;
            }
            return this.get(`${ENDPOINTS.FILESANDCACHE.ARCHIVE.BUILD_WORKER}${customerId}`);
        };
        this.getStatusArchive = () => {
            const customerId = AISCacheService.getCustomerId();
            if (!customerId) {
                return null;
            }
            return this.get(`${ENDPOINTS.FILESANDCACHE.ARCHIVE.GETSTATUS}${customerId}`);
        };
        this.deleteRedisKeyCheckFiles = () => {
            const customerId = AISCacheService.getCustomerId();
            if (!customerId) {
                return null;
            }
            return this.get(`${ENDPOINTS.FILESANDCACHE.DELETEKEY}${customerId}`);
        };
        this.deleteRedisKeyArchive = () => {
            const customerId = AISCacheService.getCustomerId();
            if (!customerId) {
                return null;
            }
            return this.get(`${ENDPOINTS.FILESANDCACHE.ARCHIVE.DELETEKEY}${customerId}`);
        };
    }
    getStoredRequestInterop(periode = 'm-12', forceRecalcul = false) {
        return this.post(ENDPOINTS.DB.GETSTOREDREQUESTINTEROP, this.getAdditionalsParamsObject({ periode, forceRecalcul }));
    }
    checkFiles() {
        return this.post(ENDPOINTS.FILESANDCACHE.CHECKFILES, this.getAdditionalsParamsObject({}));
    }
    getResultCheckFiles(fileData) {
        return this.post(ENDPOINTS.FILESANDCACHE.GETRESULTCHECKFILES, this.getAdditionalsParamsObject({ fileData }));
    }
    getLastCheckFiles() {
        return this.get(ENDPOINTS.FILESANDCACHE.GETLASTCHECKFILES + this.getAdditionalsParams());
    }
}
export const AISUserService = new AISUserDataService();
export const AISPermissionsService = new AISPermissionsDataService();
export const AISDevicesService = new AISDevicesDataService();
export const AISDocumentsSubmissionService = new AISDocumentsDataSubmissionService();
export const AISHistoryService = new AISHistoryDataService();
export const AISNotificationService = new AISNotificationDataService();
export const AISKpiService = new AISKpiDataService();
export const AISTablesService = new AISTablesDataService();
export const AISSettingsService = new AISSettingsDataService();
export const AISCustomerInteropService = new AISCustomerInteropDataService();
export const AISDispatcherService = new AISDispatcherDataService();
export const AISEnvsVarService = new AISEnvsVarDataService();
export const AISWebReportingService = new AISWebReportingDataService();
export const AISRequestService = new AISRequestDataService();
export const AISReportService = new AISReportDataService();
export const AISBucketService = new AISBucketDataService();
export const AISAdminToolsService = new AISAdminToolsDataService();
